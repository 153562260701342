import React from "react";
import "./StepsSection.css"; // Archivo CSS externo
import LaptopImage from "../../assets/landingPage/screenshot-notebook.png"; // Ruta de la imagen de la laptop

const StepsSection = () => {
  const steps = [
    {
      number: "01",
      title: "Regístrate",
      description: "Crea una cuenta utilizando tu email.",
    },
    {
      number: "02",
      title: "Personaliza",
      description:
        "Agrega tu información personal y la de tus clientes de manera rápida y sencilla.",
    },
    {
      number: "03",
      title: "Planifica",
      description:
        "Organiza tus visitas y optimiza tus rutas para ahorrar tiempo y recursos.",
    },
  ];

  return (
    <section className="steps-section">
      <div className="container second-container">
        {/* Imagen de la laptop */}
        <div className="image-container">
          <img src={LaptopImage} alt="Laptop with app preview" />
        </div>

        {/* Pasos descriptivos */}
        <div className="steps-content">
          <h2>
            <br />
            Usar Nuestra App es <span className="highlight">Fácil.</span>
          </h2>
          <div className="steps-list">
            {steps.map((step, index) => (
              <div key={index} className="step">
                <span className="step-number">{step.number}</span>
                <div className="step-text">
                  <h3>{step.title}</h3>
                  <p>{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepsSection;
